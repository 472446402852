// 
// authentication.scss
//
.bg-pattern {
    background-image: url("../images/blank-background.png");
    background-size: cover;
    background-position: center;
}

.auth-logo {
    &.logo-light {
        display: $display-none;
    }

    &.logo-dark {
        display: $display-block;
    }
}